import React, { useState } from "react";
import PropTypes from "prop-types";
import TextFieldElement from "../components/textfield/TextFieldElement";
import SelectElement from "../components/select/SelectElement";

const buyPersonal = localStorage.getItem("buyPersonal");

function getFieldValues(fieldName, storedArray) {
  if (storedArray !== null) {
    const initialValue = JSON.parse(storedArray);
    return initialValue[fieldName];
  } else {
    return "12";
  }
}

export const FirstName = ({ control, required }) => {
  //const [firstName] = useState(() => {
  //  return getFieldValues("firstName", buyPersonal);
  //});

  return (
    <TextFieldElement
      autoComplete={"given-name"}
      defaultValue={""}
      name={"firstName"}
      control={control}
      label={"First Name"}
      required={required}
      limitToLettersAndSpecialChars={true}
      pattern={/^[A-Za-z\-'\s]+$/} // Allows only letters, hyphen, apostrophe, and space
      placeholder={'Enter your first name'}
    />
  );
};

FirstName.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const LastName = ({ control, required }) => {
  //const [lastName] = useState(() => {
  //  return getFieldValues("lastName", buyPersonal);
  //});

  return (
    <TextFieldElement
      autoComplete={"family-name"}
      defaultValue={""}
      name={"lastName"}
      control={control}
      label={"Surname"}
      required={required}
      limitToLettersAndSpecialChars={true}
      pattern={/^[A-Za-z\-'\s]+$/} // Allows only letters, hyphen, apostrophe, and space
      placeholder={'Enter your surname'}
    />
  );
};

LastName.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const FullName = ({ control, required }) => {
  //const [lastName] = useState(() => {
  //  return getFieldValues("lastName", buyPersonal);
  //});

  return (
    <TextFieldElement
      autoComplete={"name"}
      defaultValue={""}
      name={"fullName"}
      control={control}
      label={"Name"}
      required={required}
      limitToLettersAndSpecialChars={true}
      pattern={/^[A-Za-z\-'\s]+$/} // Allows only letters, hyphen, apostrophe, and space
      placeholder={'Enter your full name'}
    />
  );
};

FullName.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const Email = ({ control, required }) => {
  //const [email] = useState(() => {
  //  return getFieldValues("email", buyPersonal);
  //});

  return (
    <TextFieldElement
      autoComplete={"email"}
      defaultValue={""}
      name={"email"}
      control={control}
      label={"Email Address"}
      required={required}
      placeholder={"Enter your email address"}
    />
  );
};

Email.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const Phone = ({ control, required }) => {
  //const [phone] = useState(() => {
   // return getFieldValues("phone", buyPersonal);
  //});

  return (
    <TextFieldElement
      autoComplete={"tel-national"}
      defaultValue={""}
      name={"phone"}
      control={control}
      label={"Telephone Number"}
      required={required}
      pattern={/^(?=.*\d)[\d ]+$/}
      placeholder={"Enter your phone number"}
    />
  );
};

Phone.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const Salary = ({ control, required, salary }) => {
  return (
    <TextFieldElement
      defaultValue={salary}
      name={"salary"}
      control={control}
      label={"Salary"}
      required={required}
      pattern={null}
      startAdornment={"£"}
      formatThousands={true}
    />
  );
};

Salary.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  salary: PropTypes.string,
};

export const PartnerSalary = ({ control, partnerSalary }) => {
  return (
    <TextFieldElement
      defaultValue={partnerSalary}
      name={"partnerSalary"}
      control={control}
      label={"Partner's Salary"}
      required={false}
      pattern={null}
      startAdornment={"£"}
      formatThousands={true}
    />
  );
};

PartnerSalary.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  partnerSalary: PropTypes.string,
};

export const Iama = ({ control, required }) => {
  const [iama] = useState(() => {
    return getFieldValues("iama", buyPersonal);
  });

  const options = [
    {
      value: "1",
      label: "Property Buyer",
    },
    {
      value: "2",
      label: "Property Owner",
    },
    {
      value: "3",
      label: "Property Seller",
    },
    {
      value: "16",
      label: "Other",
    },
  ];

  return (
    <SelectElement
      defaultValue={iama}
      name={"iama"}
      control={control}
      label={"I am a..."}
      required={required}
      options={options}
    />
  );
};

Iama.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};
